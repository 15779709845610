import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const ExploreCourseTaskSwitch = ({ switchMode, isCourse = true }) => {
  const displaySelection = isCourse ? "Courses" : "Lessons";
  const dropdownSelection = isCourse ? "Lessons" : "Courses";
  const ToggleComponent = () => {
    return (
      <>
        <span className="d-lg-none text-lg">
          {displaySelection}
          <FontAwesomeIcon className="ml-1" icon={faAngleDown} />
        </span>
        <span className="d-none d-lg-block text-xl">
          {displaySelection}
          <FontAwesomeIcon className="ml-3" icon={faAngleDown} />
        </span>
      </>
    );
  };
  const DropdownItemComponent = () => {
    return (
      <>
        <span className="d-lg-none text-lg">{dropdownSelection}</span>
        <span className="d-none d-lg-block text-xl">{dropdownSelection}</span>
      </>
    );
  };
  return (
    <Dropdown>
      <Dropdown.Toggle variant="light text-primary rounded-pill py-1 mr-0 px-lg-4 px-3">
        <ToggleComponent />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item className="text-primary" onClick={() => switchMode()}>
          <DropdownItemComponent />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ExploreCourseTaskSwitch;
