const TaskOverviewSkillsBlock = ({ skillsList }) => {
  return skillsList && skillsList.length > 0 ? (
    <>
      <div className="d-flex align-items-center mb-3">
        <img
          alt="lesson skills"
          src="/img/lesson_skills.png"
          className="avatar avatar-sm"
        />
        <div className="font-weight-bold ml-2">SKILLS</div>
      </div>

      {/* <div className="pb-3">
        <div className="font-weight-bolder">Financial literacy</div>
        <div>How to find and use data to make personal financial decisions</div>
      </div>
      <div>
        <div className="font-weight-bolder">Risk analysis</div>
        <div>
          Students must identify examples of consumer risk across services and
          goods they buy, from electricity clothing
        </div>
      </div> */}
      {
        // Follow the above example to map the skillsList. The last item should not have pb-3 class.
        skillsList.map((skill, index) => {
          return (
            <div
              key={index}
              className={`${index === skillsList.length - 1 ? "" : "mb-3"}`}
            >
              <div className="font-weight-bolder">{skill.title}</div>
              <div className="text-pre-wrap">{skill.description}</div>
            </div>
          );
        })
      }
    </>
  ) : (
    <></>
  );
};

export default TaskOverviewSkillsBlock;
