import React, { useMemo, useState } from "react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card } from "react-bootstrap";
import {
  AWS_REGION_COUNTRY_MAP,
  COUNTRY_INFO,
  EXPLORE_PAGE_MODES,
} from "../../utils/constants";
import HorizontalGallery from "../util/HorizontalGallery";
import BoldHeaderWithSideItems from "../util/BoldHeaderWithSideItems";
import { ExploreTaskCard } from "../task/TaskCard";
import OverviewTaskModal from "./OverviewTaskModal";
import ExploreCourseTaskSwitch from "./ExploreCourseTaskSwitch";

const REGION_STR = AWS_REGION_COUNTRY_MAP[window._env_["REGION"]];

const ExploreTaskPanel = ({
  taskList,
  courseList,
  setMode,
  handleFilterUpdate,
  isSchoolCoursePurchaseModel,
  purchasedCourseList,
  isPublic = false,
}) => {
  const [showTaskModal, setShowTaskModal] = useState(null);

  const taskDisplayDataList = taskList.flatMap((task) => {
    const project = courseList.projectDict[task.projectRef];
    const course = courseList.courses.find((course) =>
      task.courseRefs.includes(course.courseRef)
    );
    if (!course) return [];
    return {
      task,
      course,
      project,
    };
  });

  const getMultipleRandom = (arr, num) => {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };
  const purchasedCourseRefList = purchasedCourseList?.map(
    (item) => item.courseRef
  );

  const FeaturedTaskDisplay = ({ tasks, title, titleColor = "dark" }) => {
    const FilterCard = () => (
      <Card
        className={
          "border-0 card-hover-overlay hover-shadow-lg " +
          "no-text-select cursor-pointer bg-warning text-center"
        }
        style={{
          boxShadow: "0 0.75rem 1.5rem rgb(18 38 63 / 10%)",
          height: "calc(100% - 1.25rem)",
        }}
        onClick={() => {
          if (title === "New") handleFilterUpdate({});
          else handleFilterUpdate({ subject: title });
          setMode(EXPLORE_PAGE_MODES.SEARCH_TASK);
          window.scrollTo(0, 0);
        }}
      >
        <div className="my-auto">
          <Card.Title className="mx-3 text-white mb-5">
            All {title} lessons
          </Card.Title>
          <div className="w-100 h-100 text-center">
            <Card.Img
              className="mt-auto mb-2"
              variant="bottom"
              style={{ width: "12rem" }}
              src={
                COUNTRY_INFO[REGION_STR].exploreFeaturedSubjects.find(
                  (item) => item.subjectName === title
                ).image
              }
            />
          </div>
        </div>
      </Card>
    );
    return (
      <div className="mb-4">
        <h3 className={`text-${titleColor} ml-4 ml-xl-5 pl-lg-2 mb-3`}>
          {title}
        </h3>
        <HorizontalGallery>
          {tasks.map((item) => {
            return (
              <ExploreTaskCard
                itemId={item.task.taskRef} // NOTE: itemId is required for track items
                key={item.task.taskRef}
                task={item.task}
                course={item.course}
                project={item.project}
                isPurchased={
                  isSchoolCoursePurchaseModel &&
                  purchasedCourseRefList?.includes(item.course.courseRef)
                }
                onClick={() =>
                  setShowTaskModal({
                    task: item.task,
                    course: item.course,
                    project: item.project,
                    shouldPurchaseCourse:
                      isSchoolCoursePurchaseModel &&
                      !purchasedCourseRefList?.includes(item.course.courseRef),
                  })
                }
              />
            );
          })}
          {title !== "New" && <FilterCard />}
        </HorizontalGallery>
      </div>
    );
  };

  const randomFeaturedSubjectsTaskList = useMemo(
    () =>
      taskDisplayDataList &&
      COUNTRY_INFO[REGION_STR].exploreFeaturedSubjects.map((subject) => {
        return {
          subject: subject.subjectName,
          tasks: getMultipleRandom(
            taskDisplayDataList.filter((item) => {
              return item.course.countryMetadata[REGION_STR].subject.includes(
                subject.subjectName
              );
            }),
            7
          ),
        };
      }),
    []
  );

  // const randomFeaturedNewCourseList = useMemo(
  //   () =>
  //     courseList &&
  //     deepCopy(courseList.courses)
  //       .sort((a, b) => (a.createAt < b.createAt ? 1 : -1))
  //       .slice(0, 7),
  //   []
  // );

  const subjectTaskList = useMemo(
    () =>
      taskDisplayDataList && (
        <>
          {/* <FeaturedTaskDisplay
            key="New"
            courses={randomFeaturedNewCourseList}
            title="New"
            titleColor="white"
          /> */}
          {randomFeaturedSubjectsTaskList.map((item, index) => (
            <FeaturedTaskDisplay
              titleColor={index !== 0 ? "dark" : "white"}
              key={item.subject}
              tasks={item.tasks}
              title={item.subject}
            />
          ))}
        </>
      ),
    []
  );

  return (
    <>
      <BoldHeaderWithSideItems
        title={
          <>
            <span className="mr-2 mr-lg-3">Explore</span>
            <ExploreCourseTaskSwitch
              switchMode={() => setMode(EXPLORE_PAGE_MODES.EXPLORE)}
              isCourse={false}
            />
          </>
        }
      >
        {/* <Can
          perform="favourite-course:teacher"
          yes={() => (
            <>
              <div
                className="d-lg-none text-light p-2 cursor-pointer"
                onClick={() => setMode(EXPLORE_PAGE_MODES.FAVOURITE)}
              >
                <FontAwesomeIcon className="mt-2" icon={faHeartRegular} />
              </div>
              <Button
                className="d-none d-lg-block mr-3 rounded-pill"
                variant="outline-light"
                size="sm"
                onClick={() => setMode(EXPLORE_PAGE_MODES.FAVOURITE)}
              >
                <FontAwesomeIcon className="mr-lg-2" icon={faHeart} />
                <span className="d-none d-lg-inline">My Favourites</span>
              </Button>
            </>
          )}
          no={() => null}
        /> */}

        <div
          className="d-lg-none text-light mx-0 p-2 mr-2 cursor-pointer"
          onClick={() => setMode(EXPLORE_PAGE_MODES.SEARCH_TASK)}
        >
          <FontAwesomeIcon className="mt-2" icon={faMagnifyingGlass} />
        </div>
        <Button
          className="d-none d-lg-block mr-5 text-light rounded-pill"
          variant="warning"
          size="sm"
          onClick={() => setMode(EXPLORE_PAGE_MODES.SEARCH_TASK)}
        >
          <FontAwesomeIcon className="mr-lg-2 m-0" icon={faMagnifyingGlass} />
          <span className="d-none d-lg-inline">Search Lessons</span>
        </Button>
      </BoldHeaderWithSideItems>
      <OverviewTaskModal
        show={showTaskModal}
        setShow={setShowTaskModal}
        isPublic={isPublic}
      />
      <div className="text-center">
        {isSchoolCoursePurchaseModel && (
          <span key={-1} className="mr-3">
            <Button
              variant="outline-light rounded-pill mb-3"
              size="sm"
              onClick={() => {
                handleFilterUpdate({ onlyShowPurchased: true });
                setMode(EXPLORE_PAGE_MODES.SEARCH_TASK);
              }}
            >
              Purchased
            </Button>
          </span>
        )}
        {COUNTRY_INFO[REGION_STR].exploreFeaturedSubjects.map((item, index) => (
          <span key={index} className="mr-3">
            <Button
              variant="outline-light rounded-pill mb-3"
              size="sm"
              onClick={() => {
                handleFilterUpdate({ subject: item.subjectName });
                setMode(EXPLORE_PAGE_MODES.SEARCH_TASK);
              }}
            >
              {item.subjectName}
            </Button>
          </span>
        ))}
      </div>
      <div className="pb-lg-4" />
      {subjectTaskList}
    </>
  );
};

export default ExploreTaskPanel;
