import React from "react";

const BoldHeaderWithSideItems = (props) => {
  return (
    <div
      className="d-flex justify-content-center mb-3"
      style={{ position: "relative" }}
    >
      <div className="d-flex justify-content-center">
        <h3 className="d-lg-none text-white mb-0">
          <strong>{props.title}</strong>
        </h3>
        <h1 className="d-none d-lg-block text-white mb-0">
          <strong>{props.title}</strong>
        </h1>
      </div>
      <div
        className="h-100 d-flex"
        style={{
          position: "absolute",
          alignItems: "center",
          ...(props.left ? { left: 0 } : { right: 0 }),
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default BoldHeaderWithSideItems;
